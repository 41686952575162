import classnames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import type { RootState } from '../../redux/slice';
import { getPartnerSettings } from '../../redux/slice/ui';
import { ModalContentOverride } from '../../typedefs';
import { E_DEN_LONG_NAME, E_DEN_PRIVACY_PAGE, E_DEN_TERMS_AND_CONDITIONS_PAGE } from '../../utils/constants';
import ExternalLink from '../common/ExternalLink';

function mapStateToProps(state: RootState) {
  const partnerSettings = getPartnerSettings(state);
  const { privacyPolicyUrl, termsConditionsUrl, name } = partnerSettings;

  return {
    privacyPolicyUrl: privacyPolicyUrl || E_DEN_PRIVACY_PAGE,
    termsConditionsUrl: termsConditionsUrl || E_DEN_TERMS_AND_CONDITIONS_PAGE,
    companyName: name || E_DEN_LONG_NAME,
  };
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type ContentProps = ModalContentOverride & PropsFromRedux;

type TermsAndConditionsState = {
  showTcpaTerms: boolean;
  renderTcpaTerms: boolean;
};

class TermsAndConditions extends React.PureComponent<ContentProps, TermsAndConditionsState> {
  constructor(props: PropsFromRedux) {
    super(props);
    this.state = {
      showTcpaTerms: false,
      renderTcpaTerms: false,
    };
  }

  render() {
    const { privacyPolicyUrl, termsConditionsUrl, companyName } = this.props;
    const { showTcpaTerms, renderTcpaTerms } = this.state;
    return (
      <div className="TermsAndConditions">
        <p className="mt-3 mb-1 text-center">
          By clicking Submit you agree to our{' '}
          <ExternalLink href={termsConditionsUrl}>Terms and Conditions</ExternalLink>,{' '}
          <ExternalLink href={privacyPolicyUrl}>Privacy Policy</ExternalLink>, and{' '}
          <button className="link-button link-primary" onClick={this.showTcpaTerms}>
            TCPA Consent
          </button>
          .
        </p>
        <p className="mb-1 text-center">We respect your privacy.</p>
        <p
          className={classnames('smallprint mb-1 text-center', {
            'd-none': !renderTcpaTerms,
            'd-block': renderTcpaTerms,
          })}
          style={{
            opacity: showTcpaTerms ? '1' : '0',
            transition: 'all .2s',
          }}
        >
          By submitting this form, you are giving your consent to receive phone calls and text messages from
          {` ${companyName}`}, its partners, and other service professionals at the phone number you inputted (including
          wireless number if provided). Contacting you is for the purpose of confirming and scheduling your HVAC project
          and contractor bids. You understand that automated phone technology, by voice or text messages, may be used to
          reach you. Please note that providing consent does not obligate you to purchase or use any product or service.
        </p>
      </div>
    );
  }

  showTcpaTerms = () => {
    this.setState({ renderTcpaTerms: true });
    setTimeout(() => this.setState({ showTcpaTerms: true }), 2);
  };
}

export default connector(TermsAndConditions);
