import { STEP_NAMES } from '../../utils/constants';
import HeroPanel from '../common/HeroPanel';

const getEdenQuote = () => {
  return (
    <div className="quote fs-5">EDEN Homes helps homeowners get perfect comfort and save on a new HVAC system.</div>
  );
};

const getPartnerQuote = () => {
  return (
    <>
      <div className="quote fs-5">
        Discover $2,000+ in rebates and see prices for a new HVAC system in just a few steps.
      </div>
    </>
  );
};

const IntroMobileHero = () => {
  return (
    <HeroPanel
      mobile
      edenQuote={getEdenQuote()}
      partnerQuote={getPartnerQuote()}
      heroImageAlt="pleasant sunlit room"
      heroImageUrl="heroPanels/indoor-room.webp"
      imageWidth={80}
      stepName={STEP_NAMES.INTRO_MOBILE}
    />
  );
};

export default IntroMobileHero;
