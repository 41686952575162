import autoBind from 'auto-bind';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { connect, ConnectedProps } from 'react-redux';
import type { RootState } from '../../redux/slice';
import { isRepairSubmitSuccess } from '../../redux/slice/formData';
import { isSaving, isSubmitting } from '../../redux/slice/projects';
import { checkRepairForm, submitRepair } from '../../redux/thunks';
import type { RepairEmailType } from '../../utils/enums';

type RepairButtonOwnProps = {
  className?: string;
  emailType: RepairEmailType;
};

function mapStateToProps(store: RootState) {
  const repairSubmitSuccess = isRepairSubmitSuccess(store);
  return {
    repairSubmitSuccess,
    isSaving: isSaving(store),
    isSubmitting: isSubmitting(store),
  };
}

const mapDispatchToProps = {
  submitRepair,
  checkRepairForm,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type RepairButtonProps = PropsFromRedux & RepairButtonOwnProps;

class RepairButton extends React.PureComponent<RepairButtonProps> {
  constructor(props: RepairButtonProps) {
    super(props);
    autoBind(this);
  }

  onSubmitClick() {
    const { submitRepair, checkRepairForm, emailType } = this.props;
    const onSuccess = () => submitRepair(emailType);
    checkRepairForm({ onSuccess });
  }

  render() {
    const { isSubmitting, isSaving, className, repairSubmitSuccess } = this.props;
    return (
      <>
        {isSubmitting && (
          <div className="spinner-border text-muted" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        <Button
          className={className}
          variant="primary"
          disabled={isSaving || isSubmitting || repairSubmitSuccess}
          onClick={this.onSubmitClick}
        >
          Submit
        </Button>
      </>
    );
  }
}

export default connector(RepairButton);
