import { default as classnames } from 'classnames';
import React, { ChangeEventHandler, FocusEventHandler } from 'react';
import { getFieldErrors } from '../../redux/slice/errors';
import { useAppSelector } from '../../redux/store';
import { FormErrors } from '../../typedefs';
import ElementWrapper from './ElementWrapper';

type FormTextAreaValue = string | readonly string[];

type FormTextAreaProps = {
  name: string;
  label: string;
  className?: string;
  cols?: number;
  rows?: number;
  value?: FormTextAreaValue;
  placeholder?: string;
  description?: string | React.ReactNode;
  required?: boolean;
  compact?: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  errors?: FormErrors;
};

const FormTextArea: React.FC<FormTextAreaProps> = ({
  name,
  value,
  className,
  cols,
  rows,
  required,
  description,
  label,
  placeholder,
  compact,
  onChange,
  onFocus,
  onBlur,
  errors,
}) => {
  const fieldErrors = useAppSelector((state) => (errors && errors.fieldErrors) || getFieldErrors(state));
  const specificFieldErrors = fieldErrors[name];

  return (
    <ElementWrapper
      className={classnames('FormTextArea', className)}
      name={name}
      compact={compact}
      label={label}
      description={description}
      specificFieldErrors={specificFieldErrors}
    >
      <textarea
        className={classnames('form-control mt-2', {
          'is-invalid': specificFieldErrors,
        })}
        id={name}
        required={required}
        value={value || ''}
        cols={cols}
        rows={rows}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder && `Ex: ${placeholder}`}
      />
    </ElementWrapper>
  );
};

export default FormTextArea;
