import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import type { RootState } from '../../redux/slice';
import { formActions, getFormData } from '../../redux/slice/formData';
import { checkAndUpdateProject } from '../../redux/thunks';
import FormUtil from '../../utils/formUtil';
import FormElement from '../common/FormElement';

type ContactInputOwnProps = {
  showPhoneInputOnIntro?: boolean;
  showEmailInputOnIntro?: boolean;
};

function mapStateToProps(state: RootState) {
  const { firstName, lastName, email, phone } = getFormData(state);

  return {
    firstName,
    lastName,
    email,
    phone,
  };
}

const { updateField } = formActions;

const mapDispatchToProps = {
  updateField,
  checkAndUpdateProject,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ContactInputsProps = PropsFromRedux & ContactInputOwnProps;

class ContactInputs extends React.PureComponent<ContactInputsProps> {
  handleFirstNameUpdate: (e: React.FormEvent<HTMLInputElement>) => void;
  handleLastNameUpdate: (e: React.FormEvent<HTMLInputElement>) => void;
  handleEmailUpdate: (e: React.FormEvent<HTMLInputElement>) => void;
  handlePhoneUpdate: (e: React.FormEvent<HTMLInputElement>) => void;

  constructor(props: ContactInputsProps) {
    super(props);
    this.handleFirstNameUpdate = FormUtil.createFieldHandler('firstName', props, { keepProjectData: true }).bind(this);
    this.handleLastNameUpdate = FormUtil.createFieldHandler('lastName', props, { keepProjectData: true }).bind(this);
    this.handleEmailUpdate = FormUtil.createFieldHandler('email', props, { keepProjectData: true }).bind(this);
    this.handlePhoneUpdate = FormUtil.createFieldHandler('phone', props, { keepProjectData: true }).bind(this);
  }

  render() {
    const { firstName, lastName, email, phone, showPhoneInputOnIntro, showEmailInputOnIntro } = this.props;

    return (
      <div className="NameAndEmailInputs mt-5">
        <h2 className="fw-bold">How can we contact you?</h2>

        <div className="d-flex flex-row">
          <FormElement
            name="firstName"
            autoComplete="given-name"
            label="First Name"
            type="text"
            className="w-50 pe-4"
            value={firstName}
            required
            onChange={this.handleFirstNameUpdate}
          />
          <FormElement
            name="lastName"
            autoComplete="family-name"
            label="Last Name"
            type="text"
            className="w-50 ps-4"
            value={lastName}
            required
            onChange={this.handleLastNameUpdate}
          />
        </div>
        {showEmailInputOnIntro && (
          <FormElement
            name="email"
            autoComplete="email"
            label="Email"
            type="email"
            className=""
            value={email}
            required
            onChange={this.handleEmailUpdate}
          />
        )}
        {showPhoneInputOnIntro && (
          <FormElement
            name="phone"
            autoComplete="tel"
            label="Phone"
            type="tel"
            className=""
            value={phone}
            required
            onChange={this.handlePhoneUpdate}
          />
        )}
      </div>
    );
  }
}

export default connector(ContactInputs);
