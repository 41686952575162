import { E_DEN_SHORT_NAME, HERO_PANEL_WIDTH, STEP_NAMES } from '../../utils/constants';
import HeroPanel from '../common/HeroPanel';

const getEdenQuote = () => {
  return (
    <>
      <div className="quote fs-5">
        “From planning to installation, {E_DEN_SHORT_NAME}'s whole process was super easy and straightforward. Their
        technology and knowledge made our retrofit hassle-free and left us with the knowledge we got the best equipment
        for a fair price.
        <br /> 11 out of 10!”
      </div>

      <div className="attribution text-primary fw-bold fs-6 mt-2 me-4">
        Matthew replaced a central AC unit with a Bosch inverter-driven heat pump.
      </div>
    </>
  );
};

const getPartnerQuote = () => {
  return (
    <>
      <h2>Discover over $2,000 in rebates for your new HVAC system</h2>
      <div className="quote fs-5">
        Maximize your savings with thousands in rebates and tax credits from government and utility programs. In just a
        few steps, we will recommend qualifying systems, calculate how much you can save, and reveal our exclusive
        prices.
      </div>
    </>
  );
};

const IntroHero = () => {
  return (
    <HeroPanel
      edenQuote={getEdenQuote()}
      partnerQuote={getPartnerQuote()}
      imageWidth={50 * HERO_PANEL_WIDTH}
      heroImageAlt="Smiling couple and big fluffy white dog standing next to a heat pump"
      heroImageUrl="heroPanels/customer-testimonial.webp"
      stepName={STEP_NAMES.INTRO}
    />
  );
};

export default IntroHero;
