import autoBind from 'auto-bind';
import classNames from 'classnames';
import { filter, includes, intersection, map } from 'lodash-es';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import MultiRoom from '../../public/images/layouts/multi-room.svg';
import RadiatorSVG from '../../public/images/layouts/radiator.svg';
import HomeSVG from '../../public/images/layouts/recommended.svg';
import SingleRoom from '../../public/images/layouts/single-room.svg';
import type { RootState } from '../../redux/slice';
import { formActions, getLayoutType, getServiceType } from '../../redux/slice/formData';
import { getSupportedLayoutsOverride } from '../../redux/slice/ui';
import { checkAndUpdateProject } from '../../redux/thunks';
import { LAYOUT_TYPE_CONTENT_OVERRIDE_KEYS, LAYOUT_TYPE_LABELS } from '../../utils/constants';
import { DuctedDeliveryType, LayoutType, ServiceType } from '../../utils/enums';
import FilterUtil from '../../utils/filterUtil';
import TrackingUtil from '../../utils/trackingUtil';
import TypeSelector, { TypeOption } from '../common/TypeSelector';

type LayoutSelectorOwnProps = {
  className?: string;
};

function mapStateToProps(state: RootState) {
  return {
    value: getLayoutType(state),
    serviceType: getServiceType(state),
    supportedLayoutsOverride: getSupportedLayoutsOverride(state),
  };
}

const mapDispatchToProps = {
  updateLayoutType: formActions.updateLayoutType,
  updateDuctedDelivery: formActions.updateDuctedDelivery,
  checkAndUpdateProject,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type LayoutSelectorProps = PropsFromRedux & LayoutSelectorOwnProps;

class LayoutSelector extends React.PureComponent<LayoutSelectorProps> {
  constructor(props: LayoutSelectorProps) {
    super(props);
    autoBind(this);
  }

  componentDidMount(): void {
    this.applySupportedLayoutTypes();
  }

  componentDidUpdate(prevProps: Readonly<LayoutSelectorProps>, prevState: Readonly<{}>, snapshot?: any): void {
    this.applySupportedLayoutTypes();
  }

  private applySupportedLayoutTypes() {
    const supportedLayoutTypes = this.determinedSupportedLayoutTypes();

    if (supportedLayoutTypes.length < 3) {
      const { value } = this.props;
      if (!includes(supportedLayoutTypes, value)) {
        this.handleLayoutChange(supportedLayoutTypes[0], { doNotTrack: true });
      }
    }
  }

  handleLayoutChange(value: LayoutType, config?: { doNotTrack?: boolean }) {
    const { updateLayoutType, updateDuctedDelivery, checkAndUpdateProject } = this.props;
    updateLayoutType(value);

    if (value === LayoutType.REPLACE_EXISTING) {
      updateDuctedDelivery(DuctedDeliveryType.YES);
    } else if (includes([LayoutType.MULTIPLE_ROOMS, LayoutType.SINGLE_ROOM], value)) {
      updateDuctedDelivery(DuctedDeliveryType.NO);
    }

    if (!config?.doNotTrack) {
      TrackingUtil.formUpdate('layoutType', value);
    }

    checkAndUpdateProject();
  }

  getOption(type: LayoutType, svg: React.ReactElement): TypeOption<LayoutType> {
    return { type, label: LAYOUT_TYPE_LABELS[type], svg };
  }

  buildOptionForLayoutType(layoutType: LayoutType) {
    switch (layoutType) {
      case LayoutType.WHOLE_HOME:
        return this.getOption(LayoutType.WHOLE_HOME, <HomeSVG />);
      case LayoutType.MULTIPLE_ROOMS:
        return this.getOption(LayoutType.MULTIPLE_ROOMS, <MultiRoom />);
      case LayoutType.SINGLE_ROOM:
        return this.getOption(LayoutType.SINGLE_ROOM, <SingleRoom />);
      case LayoutType.REPLACE_EXISTING:
        return this.getOption(LayoutType.REPLACE_EXISTING, <HomeSVG />);
      case LayoutType.BOILER:
        return this.getOption(LayoutType.BOILER, <RadiatorSVG />);
      default:
        return null;
    }
  }

  determinedSupportedLayoutTypes(): LayoutType[] {
    const { supportedLayoutsOverride, serviceType } = this.props;

    const wholeHomeLayout = serviceType === ServiceType.NEW ? LayoutType.WHOLE_HOME : LayoutType.REPLACE_EXISTING;
    const defaultLayoutTypes = [wholeHomeLayout, LayoutType.MULTIPLE_ROOMS, LayoutType.SINGLE_ROOM];

    if (supportedLayoutsOverride && supportedLayoutsOverride.length > 0) {
      const overridableLayoutTypes = [...defaultLayoutTypes, LayoutType.BOILER];
      const layoutTypes = FilterUtil.filterListUndefined(
        map(supportedLayoutsOverride, (key) => LAYOUT_TYPE_CONTENT_OVERRIDE_KEYS[key]),
      );

      const updatedLayoutTypes = this.replaceWholeHomeWithServiceAppropriateLayout(layoutTypes, wholeHomeLayout);
      return intersection(updatedLayoutTypes, overridableLayoutTypes);
    }

    return defaultLayoutTypes;
  }

  private replaceWholeHomeWithServiceAppropriateLayout(layoutTypes: LayoutType[], wholeHomeLayout: LayoutType) {
    return map(layoutTypes, (layoutType) => (layoutType === LayoutType.WHOLE_HOME ? wholeHomeLayout : layoutType));
  }

  getOptions() {
    const supportedLayoutTypes = this.determinedSupportedLayoutTypes();
    return filter(supportedLayoutTypes.map(this.buildOptionForLayoutType)) as TypeOption<LayoutType>[];
  }

  render() {
    const { value, serviceType, className } = this.props;
    const options = this.getOptions();
    if (!options || options.length <= 1) return null;
    const action = serviceType === ServiceType.REPLACE ? 'replacement' : 'solution';
    const columnSize = Math.max(12 / options.length, 3);

    return (
      <div className={classNames('LayoutSelector', className)}>
        <TypeSelector
          groupId="layoutType"
          largeCol={columnSize}
          label={`What A/C and heating ${action} are you looking for?`}
          value={value}
          options={options}
          onChange={this.handleLayoutChange}
        />
      </div>
    );
  }
}

export default connector(LayoutSelector);
