import autoBind from 'auto-bind';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { getShowRepairFormOnIntro } from '../../redux/selectors';
import type { RootState } from '../../redux/slice';
import { getServiceType } from '../../redux/slice/formData';
import { RepairEmailType, ServiceType } from '../../utils/enums';
import ContactTelephoneNumber from '../common/ContactTelephoneNumber';
import RepairForm from './RepairForm';

function mapStateToProps(state: RootState) {
  return {
    showRepairForm: getShowRepairFormOnIntro(state),
    value: getServiceType(state),
  };
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class ServicePreview extends React.PureComponent<PropsFromRedux> {
  constructor(props: PropsFromRedux) {
    super(props);
    autoBind(this);
  }

  getTextOnly({ title, description }: { title?: string; description: string }) {
    return (
      <>
        {title && <div className="fw-bold pb-3">{title}</div>}
        <div className="text-muted">{description}</div>
      </>
    );
  }

  getContents() {
    const { value, showRepairForm } = this.props;
    switch (value) {
      case ServiceType.REPAIR:
        return showRepairForm ? (
          <RepairForm emailType={RepairEmailType.REPAIR} />
        ) : (
          <>
            <div className="fw-bold pb-3">Schedule a service call</div>
            <div className="text-muted">
              Call us at <ContactTelephoneNumber inline />. We'll have one of our technicians come out and help resolve
              your issue.
            </div>
          </>
        );
      default:
        return this.getTextOnly({
          description: "Tell us about your project and we'll be happy to show you your options",
        });
    }
  }

  render() {
    const { value } = this.props;
    if (value === ServiceType.NEW || value === ServiceType.REPLACE) {
      return <></>;
    }
    return (
      <div className="LayoutPreview">
        <div className="highlight rounded p-3">{this.getContents()}</div>
      </div>
    );
  }
}

export default connector(ServicePreview);
