import autoBind from 'auto-bind';
import React from 'react';
import { PlaceData } from '../../../typedefs';
import AutoCompleteUtil from '../../../utils/autoCompleteUtil';
import { VALID_ADDRESS_COUNTRIES } from '../../../utils/constants';
import FormElement from '../../common/FormElement';

type RawAutoCompleteProps = {
  label?: string;
  description?: string;
  className?: string;
  compact?: boolean;
  onPlaceLoaded: (place: PlaceData) => void;
  mapsSuccess: boolean;
  value: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
};

type RawAutoCompleteState = { initialized: boolean };

const PLACE_FIELDS = ['address_components', 'geometry'];
const MIN_ADDR_LENGTH = 5;

export default class RawAutoComplete extends React.Component<RawAutoCompleteProps, RawAutoCompleteState> {
  autocompleteInput: React.RefObject<HTMLInputElement>;
  autocomplete?: google.maps.places.Autocomplete;
  autocompleteService?: google.maps.places.AutocompleteService;
  placeService?: google.maps.places.PlacesService;

  constructor(props: RawAutoCompleteProps) {
    super(props);
    this.state = { initialized: false };
    this.autocompleteInput = React.createRef();
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    autoBind(this);
  }

  componentDidUpdate() {
    this.setupGoogleMapsIfNecessary();
  }

  setupGoogleMapsIfNecessary() {
    const { mapsSuccess } = this.props;
    const { initialized } = this.state;
    if (mapsSuccess && !initialized) {
      this.setState({ initialized: true });
      this.setupGoogleMaps();
    }
  }

  setupGoogleMaps() {
    if (this.autocompleteInput.current && globalThis.google) {
      this.autocompleteService = new globalThis.google.maps.places.AutocompleteService();
      this.placeService = new globalThis.google.maps.places.PlacesService(this.autocompleteInput.current);
      this.autocomplete = new globalThis.google.maps.places.Autocomplete(this.autocompleteInput.current, {
        types: ['address'],
        componentRestrictions: { country: VALID_ADDRESS_COUNTRIES },
        fields: PLACE_FIELDS,
      });

      this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
    }
  }

  async handlePlaceChanged() {
    if (this.autocomplete) {
      const place = this.autocomplete.getPlace();
      await this.notifyPlaceLoaded(place);
    }
  }

  async notifyPlaceLoaded(place: google.maps.places.PlaceResult | null) {
    if (!place) return;
    const ret = AutoCompleteUtil.getPlaceData(place);
    const elevationInFt = await AutoCompleteUtil.retrieveElevationForPlace(place);

    this.props.onPlaceLoaded({ ...ret, elevationInFt });
  }

  loadAddressWhenPrefilled(newVal: string) {
    const oldVal = this.props.value;
    if (oldVal === '' && newVal.length >= MIN_ADDR_LENGTH && this.autocompleteService && this.placeService) {
      this.autocompleteService.getPlacePredictions({ input: newVal }, (predictions) => {
        if (!predictions || !predictions.length) return;
        const place = predictions[0].place_id;
        this.placeService?.getDetails({ placeId: place, fields: PLACE_FIELDS }, (result) => {
          this.notifyPlaceLoaded(result);
        });
      });
    }
  }

  handleOnChange(e: React.FormEvent<HTMLInputElement>): void {
    this.setupGoogleMapsIfNecessary();
    this.loadAddressWhenPrefilled(e.currentTarget.value);
    this.props.onChange(e);
  }

  render() {
    const { label, value, className, compact, description } = this.props;
    return (
      <FormElement
        name="streetAddress"
        autoComplete="address-line1"
        type="text"
        className={`hide-placeholder ${className}`}
        label={label || 'Address'}
        description={description}
        compact={compact}
        placeholder={compact ? '' : '123 Main St'}
        value={value}
        onChange={this.handleOnChange}
        inputRef={this.autocompleteInput}
      />
    );
  }
}
