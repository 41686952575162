import autoBind from 'auto-bind';
import { assign } from 'lodash-es';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import type { RootState } from '../../redux/slice';
import { formActions, getFormData, isLoadingPropertyData } from '../../redux/slice/formData';
import { getDefaultZip } from '../../redux/slice/ui';
import {
  checkAndUpdateProject,
  fetchNeighborhoodInfo,
  fetchPropertyData,
  validateAddressThunk,
} from '../../redux/thunks';
import { CheckAndUpdateArgs, PlaceData, type FormFields } from '../../typedefs';
import FormUtil from '../../utils/formUtil';
import NumberUtil from '../../utils/numberUtil';
import FormElement from '../common/FormElement';
import RawAutoComplete from './AutoComplete/RawAutoComplete';

type CombinedAddressAndZipInputsOwnProps = {
  streetAddressBehavior?: CheckAndUpdateArgs;
};

function mapStateToProps(store: RootState) {
  const zipPlaceholder = getDefaultZip(store);
  return {
    zipPlaceholder,
    ...getFormData(store),
    isLoadingPropertyData: isLoadingPropertyData(store),
  };
}

const { updateField, setPlaceData } = formActions;

const mapDispatchToProps = {
  updateField,
  checkAndUpdateProject,
  fetchPropertyData,
  setPlaceData,
  validateAddressThunk,
  fetchNeighborhoodInfo,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type CombinedAddressAndZipInputsProps = CombinedAddressAndZipInputsOwnProps & PropsFromRedux;

type CombinedAddressAndZipInputsState = { zipCode: string; streetAddress: string };

class CombinedAddressAndZipInputs extends React.PureComponent<
  CombinedAddressAndZipInputsProps,
  CombinedAddressAndZipInputsState
> {
  handleZipInputUpdate: (e: React.FormEvent<HTMLInputElement>) => void;
  updateStreetAddress: (e: React.FormEvent<HTMLInputElement>) => void;
  updateAddressFormDataFromAddressAutocomplete: (place: PlaceData) => void;
  handleAddressInputUpdatedFromAutocomplete: (place: PlaceData) => void;

  constructor(props: CombinedAddressAndZipInputsProps) {
    super(props);

    const fetchNeighborHoodInfoIfZipCodeChanged = async (field: keyof FormFields, value: string): Promise<void> => {
      // This is supplied as a postChangeAction and is always called after updateField and before checkAndUpdateProject.
      if (field === 'zipCode') {
        props.fetchNeighborhoodInfo();
      }
    };

    this.handleZipInputUpdate = FormUtil.createFieldHandler('zipCode', props, {
      postChangeAction: fetchNeighborHoodInfoIfZipCodeChanged,
    });

    const streetAddressCheckAndUpdateArgs = assign({ clearPlaceData: true }, props.streetAddressBehavior);
    this.updateStreetAddress = FormUtil.createFieldHandler('streetAddress', props, streetAddressCheckAndUpdateArgs);

    this.updateAddressFormDataFromAddressAutocomplete = FormUtil.createAddressAutocompleteChangedHandler(props, {
      postChangeAction: fetchNeighborHoodInfoIfZipCodeChanged,
    });

    this.handleAddressInputUpdatedFromAutocomplete = (place: PlaceData) => {
      if (!place.zipCode) {
        props.validateAddressThunk();
        return;
      }

      this.updateAddressFormDataFromAddressAutocomplete(place);
    };

    autoBind(this);
  }

  render() {
    return (
      <div className="CombinedAddressAndZipInputs">
        {this.renderAutoComplete()}
        {this.renderZipCode()}
      </div>
    );
  }

  renderAutoComplete() {
    const { streetAddress } = this.props;

    return (
      <div className="AutoComplete">
        <RawAutoComplete
          label="Your Address"
          value={streetAddress || ''}
          onChange={this.updateStreetAddress}
          onPlaceLoaded={this.handleAddressInputUpdatedFromAutocomplete}
          mapsSuccess={true}
        />
      </div>
    );
  }

  renderZipCode() {
    const { zipCode, zipPlaceholder, isLoadingPropertyData } = this.props;
    return (
      <div className="d-flex align-items-center">
        <FormElement
          name="zipCode"
          autoComplete="postal-code"
          className="ZipCode no-spin w-50 pe-4"
          label="Zip Code"
          type="number"
          value={zipCode}
          onChange={this.handleZipInputUpdate}
          placeholder={NumberUtil.toString(zipPlaceholder, { padLeadingZerosToWidth: 5 })}
        />
        {isLoadingPropertyData && <Spinner className="ms-3" />}
      </div>
    );
  }
}

export default connector(CombinedAddressAndZipInputs);
