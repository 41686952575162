import React from 'react';
import { Alert } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import type { RootState } from '../../redux/slice';
import { formActions, getFormData, isRepairSubmitSuccess } from '../../redux/slice/formData';
import { getPartnerSettings, getStepOverrides } from '../../redux/slice/ui';
import { checkAndUpdateProject, fetchFinancing, fetchPropertyData, fetchRebates } from '../../redux/thunks';
import { ModalContentOverride } from '../../typedefs';
import {
  E_DEN_LONG_NAME,
  E_DEN_PRIVACY_PAGE,
  E_DEN_TERMS_AND_CONDITIONS_PAGE,
  STEP_NAMES,
} from '../../utils/constants';
import { LayoutType, ServiceType, type RepairEmailType } from '../../utils/enums';
import FormUtil from '../../utils/formUtil';
import FormElement from '../common/FormElement';
import FormTextArea from '../common/FormTextArea';
import TermsAndConditions from '../common/TermsAndConditions';
import CombinedAddressAndZipInputs from '../location/CombinedAddressAndZipInputs';
import RepairButton from '../modals/RepairButton';

type RepairFormOwnProps = {
  emailType: RepairEmailType;
};

function mapStateToProps(state: RootState) {
  const { firstName, lastName, email, phone, repairDetails, serviceType, layoutType } = getFormData(state);

  const partnerSettings = getPartnerSettings(state);
  const { privacyPolicyUrl, termsConditionsUrl, name } = partnerSettings;
  const contentOverride = getStepOverrides(state, STEP_NAMES.INTRO);
  const repairSubmitSuccess = isRepairSubmitSuccess(state);

  return {
    firstName,
    lastName,
    email,
    phone,
    repairDetails,
    contentOverride,
    serviceType,
    layoutType,
    privacyPolicyUrl: privacyPolicyUrl || E_DEN_PRIVACY_PAGE,
    termsConditionsUrl: termsConditionsUrl || E_DEN_TERMS_AND_CONDITIONS_PAGE,
    companyName: name || E_DEN_LONG_NAME,
    repairSubmitSuccess,
  };
}

const { updateField, setPlaceData } = formActions;

const mapDispatchToProps = {
  updateField,
  checkAndUpdateProject,
  fetchRebates,
  fetchFinancing,
  fetchPropertyData,
  setPlaceData,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type RepairFormProps = RepairFormOwnProps & ModalContentOverride & PropsFromRedux;

class RepairForm extends React.PureComponent<RepairFormProps> {
  handleFirstNameUpdate: (e: React.FormEvent<HTMLInputElement>) => void;
  handleLastNameUpdate: (e: React.FormEvent<HTMLInputElement>) => void;
  handleEmailUpdate: (e: React.FormEvent<HTMLInputElement>) => void;
  handlePhoneUpdate: (e: React.FormEvent<HTMLInputElement>) => void;
  handleRepairDetailsUpdate: (e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) => void;

  constructor(props: RepairFormProps) {
    super(props);
    this.handleFirstNameUpdate = FormUtil.createFieldHandler('firstName', props, { keepProjectData: true }).bind(this);
    this.handleLastNameUpdate = FormUtil.createFieldHandler('lastName', props, { keepProjectData: true }).bind(this);
    this.handleEmailUpdate = FormUtil.createFieldHandler('email', props, { keepProjectData: true }).bind(this);
    this.handlePhoneUpdate = FormUtil.createFieldHandler('phone', props, { keepProjectData: true }).bind(this);
    this.handleRepairDetailsUpdate = FormUtil.createFieldHandler('repairDetails', props, {
      keepProjectData: true,
    }).bind(this);
  }

  render() {
    const { firstName, lastName, email, phone, repairDetails, repairSubmitSuccess, emailType } = this.props;
    const repairIntro = this.getRepairIntroText();
    const repairDetailsLabel = this.getRepairDetailsLabel();
    const repairDetailsDescription = this.getRepairDetailsDescription();
    return (
      <div className="RepairForm">
        <p>{repairIntro}</p>
        <FormElement
          name="firstName"
          autoComplete="given-name"
          label="First Name"
          type="text"
          className="wide"
          value={firstName}
          required
          onChange={this.handleFirstNameUpdate}
        />
        <FormElement
          name="lastName"
          autoComplete="family-name"
          label="Last Name"
          type="text"
          className="wide"
          value={lastName}
          required
          onChange={this.handleLastNameUpdate}
        />
        <CombinedAddressAndZipInputs streetAddressBehavior={{ keepProjectData: true }} />
        <FormElement
          name="email"
          autoComplete="email"
          label="Email"
          type="text"
          className="wide"
          value={email}
          required
          onChange={this.handleEmailUpdate}
        />
        <FormElement
          name="phone"
          autoComplete="tel"
          label="Phone"
          type="text"
          className="wide"
          value={phone}
          required
          onChange={this.handlePhoneUpdate}
        />
        <FormTextArea
          name="repairDetails"
          label={repairDetailsLabel}
          description={repairDetailsDescription}
          className="wide"
          rows={3}
          value={repairDetails}
          required
          onChange={this.handleRepairDetailsUpdate}
        />
        <TermsAndConditions />
        {repairSubmitSuccess && (
          <Alert variant="success" className="mt-2">
            Request successfully submitted! We'll get back to you as soon as possible. You can safely close the page.
          </Alert>
        )}
        <div className="d-flex justify-content-end">
          <RepairButton className="align-right" emailType={emailType} />
        </div>
      </div>
    );
  }

  private getRepairIntroText() {
    const { serviceType, layoutType, contentOverride } = this.props;
    if (serviceType !== ServiceType.REPAIR && layoutType === LayoutType.BOILER) {
      return (
        contentOverride?.boilerIntro ||
        `Please fill out this form, and we will reach out to promptly schedule a home visit.`
      );
    }

    return (
      contentOverride?.repairIntro ||
      `Please fill out this form, and we will reach out to promptly schedule a service call.`
    );
  }

  private getRepairDetailsLabel() {
    const { serviceType, layoutType } = this.props;
    if (serviceType !== ServiceType.REPAIR && layoutType === LayoutType.BOILER) {
      return 'Please include any other details that may be helpful.';
    }

    return 'Please describe the issue';
  }

  private getRepairDetailsDescription() {
    const { serviceType, layoutType } = this.props;
    if (serviceType !== ServiceType.REPAIR && layoutType === LayoutType.BOILER) {
      return undefined;
    }

    return 'No heat/cooling, leaks, noise';
  }
}

export default connector(RepairForm);
