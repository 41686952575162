import InstantQuote from '../../../components/InstantQuote';
import InstantQuotePageHead from '../../../components/InstantQuotePageHead';
import Intro from '../../../components/intro/Intro';
import IntroHero from '../../../components/intro/IntroHero';
import StyleWrapper from '../../../components/StyleWrapper';
import InitializationUtil from '../../../utils/initializationUtil';

export const getStaticPaths = InitializationUtil.getCommonStaticPaths;

export const getStaticProps = InitializationUtil.getCommonStaticProps;

export default function Index() {
  return (
    <StyleWrapper>
      <InstantQuotePageHead stepName="Intro" />
      <InstantQuote leftPanel={<IntroHero />} rightPanel={<Intro />} />
    </StyleWrapper>
  );
}
